<template>
    <div class="shop_main noMenu" style="background: #FFF">
        <menuCar type="2" />

        <div class="shop_carConfirm" v-loading="isRequestLoading">
            <div class="shop_carConfirm_address">
                <div class="header">
                    <h1 class="title">收货人信息</h1>
                    <a href="javascript:void(0)" class="link" @click="isPopupAdd = true">【+新增收货地址】</a>
                </div>

                <ul class="list">
                    <li v-for="(item, index) in addressData" :key="index" :class="defaultAddress === item.id ? 'on' : ''" @click="defaultAddress = item.id">
                        <span class="text">{{item.name}} 收 {{item.phone}}</span>
                        <div class="line"></div>
                        <span class="text">{{item.province}} {{item.city}} {{item.area}} {{item.address}}</span>
                    </li>
                </ul>
            </div>

            <div class="shop_carConfirm_pay">
                <h1 class="title">支付方式</h1>
                <ul class="list">
<!--                    <li class="on">担保交易-->
<!--                        <el-tooltip class="item" placement="bottom" effect="light">-->
<!--                            <div slot="content">买家打款至三方支付公司，买家下单后需<span style="color: #FA8534">5天<br />内</span>完成付款（逾期未付款订单将关闭）；卖家<br />发货后，买家需<span style="color: #FA8534">10天内</span>完成结算操作（逾期未<br />结算或未延长结算期限，<span style="color: #FA8534">系统将自动结算</span>），<br />支付公司打款至卖家；<span style="color: #FA8534">支持线上退款退货</span>。</div>-->
<!--                            <img src="../../../assets/img/problem.png" width="18" height="18" class="ml10" />-->
<!--                        </el-tooltip>-->
<!--                        <img src="../../../assets/img/tabChange.png" class="bg" />-->
<!--                    </li>-->
                    <li :class="{on: payWay === 1}" @click="payWay = 1">直接打款
                        <el-tooltip class="item" placement="bottom" effect="light">
                            <div slot="content">买家通过三方支付公司<span style="color: #FA8534">直接线上打款至卖家账<br />户</span>，买家下单后需在<span style="color: #FA8534">5天内</span>完成付款（逾期未<br />付款订单将关闭）；<span style="color: #FA8534">不支持线上退款退货</span>。</div>
                            <img src="../../../assets/img/problem.png" width="18" height="18" class="ml10" />
                        </el-tooltip>
                        <img src="../../../assets/img/tabChange.png" class="bg" /></li>
                    <li :class="{on: payWay === 2}" @click="payWay = 2">线下支付
                        <el-tooltip class="item" placement="bottom" effect="light">
                            <div slot="content">买家线下账户打款，买家下单后需在<span style="color: #FA8534">5天内</span>上<br />传付款凭证（逾期未上传订单将关闭）<span style="color: #FA8534">不支<br />持线上退款退货。</span></div>
                            <img src="../../../assets/img/problem.png" width="18" height="18" class="ml10" />
                        </el-tooltip>
                        <img src="../../../assets/img/tabChange.png" class="bg" />
                    </li>
                </ul>
            </div>

            <div class="shop_carConfirm_list" v-for="(item, index) in listData" :key="index">
                <div class="header">
                    <span class="title">确认订单</span>
                    <a href="javascript:void(0)" class="link" @click="$router.replace('/shop/car')" v-if="$route.query.type === '1'">返回修改购物车</a>
                </div>
                <div class="info">
                    <div class="goods">
                        <div class="detail">
                            <span class="shop">商家：{{item.store.storeName}}</span>
                            <div class="item" v-for="(good, i) in item.shoppingCartList" :key="i">
                                <el-image :src="imageURL + good.goodsImageUrl" fit="contain"></el-image>
                                <div class="name">
                                    <span class="text">{{good.goodsName}}</span>
                                    <span class="desc">
                                        <template v-for="(sku, j) in good.sku">
                                            <template v-if="Number(j) > 0">，</template>
                                            {{sku.skuKey}}：{{sku.skuValue}}
                                        </template>
                                    </span>
                                </div>
                                <div class="num">
                                    <span>¥ {{good.price}}</span>
                                </div>
                                <div class="num">
                                    <span>×{{good.num}}</span>
                                </div>
                                <div class="num">
                                    <span class="bold">{{Number(good.goodsStock) >= Number(good.num) ? '有货' : '无货'}}</span>
                                </div>
                            </div>
                        </div>

                        <div class="form">
                            <span class="label">给商家留言：</span>
                            <el-input type="textarea" v-model="item.remark" :rows="3" placeholder="填选：对本次交易的特别说明（建议填写与商家协商一致的说明）"></el-input>
                        </div>
                    </div>

                    <div class="type">
                        <div class="list">
                            <span class="title">成交方式</span>
                            <ul>
                                <li class="on">全额付款<img src="../../../assets/img/tabChange.png" /></li>
<!--                                <li class="on else">预付款<span>（预付款占比30%）</span><img src="../../../assets/img/tabChange.png" /></li>-->
<!--                                <li class="on">赊销<img src="../../../assets/img/tabChange.png" /></li>-->
                            </ul>
                        </div>

                        <div>
                            <div class="contract">
                                <div class="label">采购合同：</div>
                                <div class="input">
                                    <el-select v-model="item.contractId" placeholder="请选择采购合同" size="small" class="fullInput">
                                        <el-option v-for="(contract, j) in contractList" :key="j" :label="contract.companyName + '(' + contract.number + ')'" :value="contract.id"></el-option>
                                    </el-select>
                                </div>
                                <el-button type="text" @click="isPopupAddContract = true">新增合同</el-button>
                            </div>

                            <div class="price">
                                <span class="text">店铺合计：<span>¥ {{item.price}}</span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="shop_carConfirm_footer">
                <span class="text">{{num}}件商品，商品总金额：¥ {{price}}</span>
                <span class="price">应付总金额： <span>¥ {{price}}</span></span>
            </div>

            <div class="shop_carConfirm_btn">
                <el-button type="primary" @click="submitOrder" :loading="isSubmitLoading">提交订单</el-button>
            </div>
        </div>

        <el-dialog class="popup_dialog" title="新增收货地址" :visible.sync="isPopupAdd" :close-on-click-modal="false" :show-close="false" :destroy-on-close="true" width="600px">
            <el-form ref="formAdd" :model="formAdd" :rules="rules" label-position="top" size="small">
                <el-form-item label="地址信息：" prop="province">
                    <el-cascader :options="regionData" v-model="addressAdd" @change="addressChangeAdd" placeholder="请选择省/市/区" :props="{ value: 'label' }"></el-cascader>
                </el-form-item>
                <el-form-item label="详细地址：" prop="address">
                    <el-input v-model="formAdd.address" placeholder="请填写详细地址"></el-input>
                </el-form-item>
                <el-form-item label="邮政编码：" prop="postcode">
                    <el-input v-model="formAdd.postcode" placeholder="请填写邮政编码"></el-input>
                </el-form-item>
                <el-form-item label="收货人姓名：" prop="name">
                    <el-input v-model="formAdd.name" placeholder="长度不超过20个字符"></el-input>
                </el-form-item>
                <el-form-item label="手机号码：" prop="phone">
                    <el-select v-model="region" placeholder="请选择省/市/区/街道" style="width: 20%" class="mrb4">
                        <el-option label="中国 +86" value="86"></el-option>
                    </el-select>
                    <el-input v-model="formAdd.phone" placeholder="请填写11位数的手机号码" style="width: 76%"></el-input>
                </el-form-item>
                <el-form-item class="mb12">
                    <el-checkbox v-model="formAdd.isDefault" :true-label="1" :false-label="2" label="设置为默认地址" name="type"></el-checkbox>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" :loading="submitLoading" @click="submitFormEdit('formAdd')">保 存</el-button>
                    <el-button type="default" @click="isPopupAdd = false">取 消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

        <addContract :isPopupAdd="isPopupAddContract" :companyList="companyList" @toAddSuccess="toGetContract" @close="isPopupAddContract = false" />
    </div>
</template>

<script>
  import {regionData} from "element-china-area-data";
  import {getAddress, postAddress} from "../../../api/shop/address";
  import {postOrder} from "../../../api/shop/car";
  import {getContractList} from "../../../api/shop/contract";
  import NP from 'number-precision'
  import {imageURL} from "../../../utils/config";
  import {getCompany} from "../../../api/shop/contract";
  import {toFixed} from "../../../utils/method";
  const menuCar = () => import('./menu')
  const addContract = () => import('../../../components/layout/shop/addContract')
  export default {
    name: "shopHelp",
    data () {
      return {
        isRequestLoading: false,
        addressData: [],
        defaultAddress: null,
        listData: [],
        imageURL: imageURL,
        num: 0,
        price: 0,
        isSubmitLoading: false,
        formAdd: {
          province: '',
          city: '',
          area: '',
          address: '',
          postcode: '',
          name: '',
          phone: '',
          isDefault: 1
        },
        addressAdd: '',
        rules: {
          province: [
            { required: true, message: '请选择省/市/区', trigger: 'change' }
          ],
          address: [
            { required: true, message: '请填写详细地址', trigger: 'blur' }
          ],
          postcode: [
            { required: true, message: '请填写邮政编码', trigger: 'blur' }
          ],
          name: [
            { required: true, message: '请填写收货人姓名', trigger: 'blur' },
            { max: 20, message: '收货人姓名长度不超过20个字符', trigger: 'blur' }
          ],
          phone: [
            { required: true, message: '请填写手机号码', trigger: 'blur' },
            { pattern: '^1[3456789]\\d{9}$', message: '请填写11位数的手机号码', trigger: 'blur'}
          ],
        },
        region: '86',
        isPopupAdd: false,
        submitLoading: false,
        regionData: regionData,
        companyList: [],
        isPopupAddContract: false,
        contractList: [],
        payWay: 1
      }
    },
    created() {
      this.$store.commit('changeScrollTop');
      this.getAddressList()
      this.init()
      this.toGetContract()
      this.getCompany()
    },
    methods: {
      getAddressList () {
        this.isRequestLoading = true
        getAddress().then(res => {
          this.isRequestLoading = false
          if (res.code === 0) {
            if (res.data.length === 0) {
              return false
            }
            this.addressData = res.data
            this.defaultAddress = res.data.filter(item => item.isDefault === 1)[0].id
          }
        }).catch(() => {
          this.isRequestLoading = false
        })
      },
      init () {
        let data = JSON.parse(sessionStorage.getItem('submitOrderData'))
        let numAll = 0
        let priceAll = 0
        data.forEach(item => {
          let price = 0
          let num = 0
          item.shoppingCartList.forEach(good => {
            price = NP.plus(price, NP.times(good.price, good.num))
            num = NP.plus(num, good.num)
          })
          item.remark = ''
          item.price = toFixed(price, 2)
          item.num = num
          item.contractId = null
          priceAll = NP.plus(priceAll, price)
          numAll = NP.plus(numAll, num)
        })
        this.listData = data
        this.num = numAll
        this.price = toFixed(priceAll, 2)
      },
      submitOrder () {
        if (!this.defaultAddress) {
          this.$message({
            message: '请选收货地址',
            type: 'warning',
            showClose: true,
          });
          return false
        }
        let isError = false
        let isChangeContract = false
        let isErrorName = ''
        let data = []
        this.listData.forEach(item => {
          if (item.contractId) {
            data.push({
              goodsInfo: item.shoppingCartList,
              num: item.num,
              price: item.price,
              remark: item.remark,
              storeId: item.store.storeId,
              storeName: item.store.storeName,
              tranStatus: 2,
              contractId: item.contractId,
              contractName: this.contractList.filter(contract => contract.id === item.contractId)[0].companyName,
              contractUrl: this.contractList.filter(contract => contract.id === item.contractId)[0].image,
              contractCode: this.contractList.filter(contract => contract.id === item.contractId)[0].number,
              contractType: this.contractList.filter(contract => contract.id === item.contractId)[0].type
            })
          } else {
            isChangeContract = true
            data.push({
              goodsInfo: item.shoppingCartList,
              num: item.num,
              price: item.price,
              remark: item.remark,
              storeId: item.store.storeId,
              storeName: item.store.storeName,
              tranStatus: 2,
              contractId: '',
              contractName: '',
              contractUrl: '',
              contractCode: '',
              contractType: ''
            })
          }

          item.shoppingCartList.forEach(good => {
            if (Number(good.goodsStock) < Number(good.num)) {
              isError  = true
              isErrorName  = good.goodsName
            }
          })
        })

        if (isError) {
          this.$message({
            message: '商品' + isErrorName + '库存不足',
            type: 'warning',
            showClose: true,
          });
          return false
        }

        if (isChangeContract) {
          this.$message({
            message: '请选择采购合同',
            type: 'warning',
            showClose: true,
          });
          return false
        }

        this.isSubmitLoading = true
        postOrder({
          data: {
            addressId: this.defaultAddress,
            num: this.num,
            price: this.price,
            orderDetailsList: data,
            channel: 1,
            payWay: this.payWay
          }
        }).then(res => {
          this.isSubmitLoading = false
          if (res.code === 0) {
            sessionStorage.setItem('uploadOrderData', JSON.stringify(res.data))
            this.$router.replace('/shop/car/upload')
          }
        }).catch(() => {
          this.isSubmitLoading = false
        })
      },
      submitFormEdit (formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.submitLoading = true
            postAddress({
              data: this.formAdd
            }).then(res => {
              this.submitLoading = false
              if (res.code === 0) {
                this.$message({
                  message: '地址添加成功',
                  showClose: true,
                  type: 'success'
                })
                this.isPopupAdd = false
                this.getAddressList()
                this.$refs[formName].resetFields()
              }
            }).catch(() => {
              this.submitLoading = false
            })
          } else {
            return false;
          }
        });
      },
      addressChangeAdd (e) {
        this.formAdd.province = e[0]
        this.formAdd.city = e[1]
        this.formAdd.area = e[2]
      },
      getCompany () {
        getCompany().then(res => {
          if (res.code === 0) {
            this.companyList = res.data
          }
        })
      },
      toGetContract () {
        getContractList().then(res => {
          if (res.code === 0) {
            this.contractList = res.data
          }
        })
      }
    },
    components: {
      menuCar, addContract
    }
  }
</script>
