import {request} from '@/utils/request'

// 获取购物车
export function getCart(channel) {
  return request('GET','/shop/cart/page/' + channel, {}, true)
}
// 编辑购物车
export function putCart(id, data) {
  return request('PUT','/shop/cart/' + id, data, true)
}
// 删除购物车
export function delCart(id) {
  return request('DELETE','/shop/cart/' + id, '', true)
}
// 提交订单
export function postOrder(data) {
  return request('POST','/shop/order', data, true)
}
// 上传支付
export function putOrder(data) {
  return request('PUT','/shop/order/edit', data, true)
}




//支付方式列表
export function channelList(data) {
  return request('POST', '/shop/payment/channel' ,data,  true);
}

// 银行卡列表
export function getBankList(data) {
  return request('POST', '/shop/bank/list', data, true);
}

// 验证卡号
export function postPaymentCardBin(data) {
  return request('POST', '/shop/bank/bin', data, true);
}

// 发送验证码
export function postPaymentSendCode(data) {
  return request('POST', '/shop/bank/sendcode', data, true);
}

// 绑卡支付
export function postPaymentBindPay(data) {
  return request('POST', '/shop/payment/bind/pay', data, true);
}

// 选卡支付
export function postPaymentChangePay(data) {
  return request('POST', '/shop/payment/choice/pay', data, true);
}

// 网银支付
export function postPaymentOnLine(data) {
  return request('POST', '/shop/payment/e-bank', data, true);
}

// 网关支付
export function postPaymentGateway(data) {
  return request('POST', '/shop/payment/paytake', data, true);
}

//支付结果
export function getPayStatus(id) {
  return request('GET', '/shop/payment/pay/' + id , {},  true);
}

// 设置支付密码
export function postPayPassword(data) {
  return request('POST', '/shop/users/payPassword', data, true);
}
